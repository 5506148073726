/* This text will be displayed below in the header of the publications page */

import { PageHeader } from '../../types';
import photo from './cover_photo.jpg';

const header: PageHeader = {
  coverPhoto: photo,
  headerStatement: `A collection of papers, articles, and other publications from the MIT Biomimetics Robotics Lab.`,
};

export default header;
